import React from "react";
import { withTranslation } from "../../../../i18n";
import GiftsView from "@/components/gifts-view";

const namespacesRequired = ["home"];

const HomeGifts = ({ t, gifts }) => {
  return (
    <section>
      <GiftsView
        gifts={gifts}
        title={t("home:gifts.title")}
        subtitle={t("home:gifts.subtitle")}
        content={t("home:gifts.content")}
        buttonLabel={t("home:gifts.btn-content")}
      />
    </section>
  );
};

export default withTranslation(namespacesRequired)(HomeGifts);
